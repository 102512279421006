import React from 'react';
import styled from 'styled-components';
import ImgUploadForm from './ImgUploadForm';
import { getFormattedValue } from '../util/CmmnUtils';
import { useTranslation } from 'react-i18next';
import Check from '@mui/icons-material/Check';

const FormWrap = styled.div`
    .row { display:flex; margin-bottom:34px; gap:20px; }
    .col {flex:1 1 0;}
    .input-label {
        display:block; font-weight:600; margin-bottom:10px; font-size:20px;
    }
    .btn {
        width:100%; text-align:center; color:#fff; background-color:#343434; 
        border-radius:8px; height:50px; line-height:1;
    }
    textarea {
        border-radius:8px; padding:20px; resize:none; outline:0; border:0; 
        width:100%; background:#F9FAFB;
    }
    .input {
        width:100%; height:50px; padding:0 16px; outline:0; border:0; 
        border-radius:8px; background:#F2F2F2;
    }
    .upload-set {
        position:relative; display:flex; gap:10px;
        input.upload-text {
        z-index: 2;
            width:calc(100% - 110px); height:50px; flex:auto; 
            border-radius:8px; outline:0; padding:0 16px; 
            border:1px solid #A0A0A0;
        }
        div.upload-btn-wrap {
            overflow:hidden; position:relative; width:110px; height:50px;
            input.input-file {
                position:absolute; top:0; right:0; cursor:pointer; opacity:0; 
                filter:alpha(opacity=0); -ms-filter:"alpha(opacity=0)"; 
                -moz-opacity:0;
            }
            label {
                display: inline-block; width:100%; height:100%; background:#F9FAFB;
                border:1px solid #A0A0A0; border-radius:8px; cursor: pointer;
                transition: transform 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
                span {
                    display: flex; justify-content: center; align-items: center;
                    height: 100%;
                }
                &:hover {
                    background-color: #E5E7EB; border-color: #6B7280;
                }
            }
        }
    }
    .agree {
        margin-bottom:36px;
        display: flex;
        flex-wrap: wrap;
        .check {
            position:relative; display:inline-flex; text-align:left; 
            vertical-align:middle; cursor:pointer;
            input {
                position:absolute; left:0; top:0; height:100%; border:none; 
                background:transparent; z-index:2;
            }
            label {
                display:inline-flex; word-break:break-word; cursor: pointer;
                align-items:center; gap:10px; 
                i, svg {
                    display:flex; align-items:center; justify-content:center; 
                    width:24px; height:24px; border:1px solid #B7BBBF; 
                    border-radius:4px; box-sizing:border-box; flex:none; 
                    background-color:#fff; transition:all .3s ease;
                    svg {
                        opacity:0; color:#fff; transition:opacity .3s ease; 
                        font-size:1.1em; display:block;
                    }
                }
            }
            input:checked + label i, input:checked + label svg {
                border-color:#343434; background-color:#343434;
                svg { opacity:1; }
            }
        }
    }
`;
const ErrorTxt = styled.div`
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    p {
        // margin-left: 1rem;
        font-size: 1.4rem;
        color: #ef2929;
    }
`;

function CmmnForm(
    {
        formData,
        onFormDataChange,
        onCheckboxChange,
        otherFields,
        onSubmit,
        checked,
        errors,
        id,
        onFileInfoUpdate,
        fileName
    }
) {
    const { t } = useTranslation();
    // let btnText;
    const btnText =
        formData && formData.iqryTypeCd === "AFIQ" ? t("common.partner") :
            formData.iqryTypeCd === "FUIQ" ? t("common.startup") :
                formData.iqryTypeCd === "GFIQ" ? t("common.abroad") : "";

    return (
        <FormWrap>
            <div className="row">
                <div className="col">
                    <div>
                        <div className="input-label">{formData && formData.iqryTypeCd === "FUIQ" ? t("startupPage.name") : t("partnerPage.coNm")}</div>
                        {formData && formData.iqryTypeCd === "FUIQ" ? (
                            errors && errors.iqrrNm && <ErrorTxt><p>{errors.iqrrNm}</p></ErrorTxt>
                        ) : (
                            errors && errors.coNm && <ErrorTxt><p>{errors.coNm}</p></ErrorTxt>
                        )}
                    </div>
                    <input
                        type="text"
                        className="input"
                        name={formData && formData.iqryTypeCd === "FUIQ" ? "iqrrNm" : "coNm"}
                        placeholder={formData && formData.iqryTypeCd === "FUIQ" ? t("common.name") : t("common.coNm")}
                        value={(formData && formData.iqryTypeCd === "FUIQ" ? formData.iqrrNm : formData.coNm) || ""}
                        onChange={onFormDataChange}
                    />
                </div>
                <div className="col">
                    <div>
                        <div className="input-label">{formData && formData.iqryTypeCd === "FUIQ" ? t("startupPage.area") : `${t("partnerPage.name")} / ${t("partnerPage.rank")}`}</div>
                        {formData && formData.iqryTypeCd === "FUIQ" ? (
                            errors && errors.founZoneNm && <ErrorTxt><p>{errors.founZoneNm}</p></ErrorTxt>
                        ) : (
                            errors && errors.iqrrNm && <ErrorTxt><p>{errors.iqrrNm}</p></ErrorTxt>
                        )}
                    </div>
                    <input
                        type="text"
                        className="input"
                        name={formData && formData.iqryTypeCd === "FUIQ" ? "founZoneNm" : "iqrrNm"}
                        placeholder={formData && formData.iqryTypeCd === "FUIQ" ? t("common.fz") : t("common.nameAndRank")}
                        value={(formData && formData.iqryTypeCd === "FUIQ" ? formData.founZoneNm : formData.iqrrNm) || ""}
                        onChange={onFormDataChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div>
                        <div className="input-label">{t("partnerPage.tel")}</div>
                        {errors && errors.iqrrTelNo && <ErrorTxt><p>{errors.iqrrTelNo}</p></ErrorTxt>}
                    </div>
                    <input
                        type="text"
                        className="input"
                        name="iqrrTelNo"
                        placeholder={t("common.tel")}
                        value={getFormattedValue(formData.iqrrTelNo) || ''}
                        onChange={onFormDataChange}
                    />
                </div>
                <div className="col">
                    <div>
                        <div className="input-label">{t("partnerPage.email")}</div>
                        {errors && errors.iqrrEmalAddr && <ErrorTxt><p>{errors.iqrrEmalAddr}</p></ErrorTxt>}
                    </div>
                    <input
                        type="text"
                        className="input"
                        name="iqrrEmalAddr"
                        placeholder={t("common.email")}
                        value={formData.iqrrEmalAddr || ''}
                        onChange={onFormDataChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="input-label">{t("partnerPage.InquiryContent")}</div>
                    <textarea
                        name="iqryCn"
                        rows="4"
                        placeholder={t("common.pstsCn")}
                        value={formData.iqryCn || ''}
                        onChange={onFormDataChange}
                    ></textarea>
                </div>
            </div>
            {otherFields}
            {(formData.iqryTypeCd === "AFIQ" || formData.iqryTypeCd === "GFIQ") && (
                <ImgUploadForm
                    onFileInfoUpdate={onFileInfoUpdate}
                    data={formData}
                    id={id}
                    fileName={fileName}
                />
            )}
            {/* <div className="agree">
                <span className="check">
                    <input
                        type="checkbox"
                        id={`agreeCheck${formData.iqryTypeCd}`}
                        name="agreeStatus"
                        checked={checked}
                        onChange={onCheckboxChange}
                    />
                    <label htmlFor={`agreeCheck${formData.iqryTypeCd}`}>
                        <i>
                            <Check />
                        </i>
                        개인정보 수집 및 이용방침 동의
                    </label>
                </span>
                {errors && <ErrorTxt><p>{errors.agreeStatus}</p></ErrorTxt>}
            </div> */}
            <button className="btn black" onClick={onSubmit}>{btnText}</button>
        </FormWrap>
    );
}

export default CmmnForm;
