import { useTranslation } from "react-i18next";

export default function ImgUploadForm({ onFileInfoUpdate, fileName, id }) {

    const { t } = useTranslation();
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // console.log(id);
            // console.log(selectedFile);
            
            onFileInfoUpdate(selectedFile);
        } else {
            onFileInfoUpdate(null);
        }

        event.target.value = ''; 
    };

    return (
        <div className="row">
            <div className="col">
                <div className="input-label">{t("partnerPage.file")}</div>
                <div className="upload-set">
                    <input
                        type="text"
                        value={fileName || ""}
                        placeholder={t("common.noFoundFile")}
                        disabled={true}
                        className="upload-text"
                    />
                    <div className="upload-btn-wrap">
                        <label htmlFor={id}>
                            <span>{t("partnerPage.find")}</span>
                        </label>
                        <input
                            type="file"
                            id={id}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            className="input-file"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

