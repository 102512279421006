import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify = (message, type = 'success', language) => {
    const jp = language === "jp";
    const jpCustomStyle = jp ? {
        fontSize: "14px"
    } : {};
    const options = {
        position: "bottom-right",
        autoClose: 2000, // 2초 후 자동 닫힘
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        style: jpCustomStyle
    };

    if (type === 'success') {
        toast.success(message, options);
    } else if (type === 'error') {
        toast.error(message, options);
    }
};

export const ToastProvider = () => {
    return <ToastContainer />;
};