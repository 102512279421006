import axios from "axios";

export async function callApi(HTTPmethod, apiBody, uri, contentType) {
    const baseApiUrl = process.env.REACT_APP_API_BASE_URL;
    const defaultContentType = contentType ? contentType : "application/json";

    try {

        const headers = {
            "Content-Type": defaultContentType,
        };

        let requestConfig = {
            method: HTTPmethod,
            url: baseApiUrl + uri,
            headers: headers,
        };

        if (HTTPmethod === "GET" && apiBody) {
            requestConfig.params = apiBody;
        } else {
            requestConfig.data = apiBody;
        }

        const res = await axios(requestConfig);


        if (res.status >= 200 && res.status < 300) {
            return res.data;
        }

    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            if (status === 404) {
                alert("", "접근 불가능한 페이지 입니다.", () => { });
            } else if (status === 401) {
                alert("", "권한이 없습니다.", () => { });
            } else {
                console.error("API Error:", error.response.data);
            }
        } else if (error.request) {
            console.error("No response from server:", error.request);
        } else {
            console.error("API Request Error:", error.message);
        }
        throw error;
    }
};