import styled from "styled-components";
import { Link } from "react-router-dom";
import main1 from "../../assets/images/home/main1.png";
import main2 from "../../assets/images/home/main2.png";
import main3 from "../../assets/images/home/main3.png";
import main4 from "../../assets/images/home/main4.png";
import main1Webp from "../../assets/images/webp/home/main1.webp";
import main2Webp from "../../assets/images/webp/home/main2.webp";
import main3Webp from "../../assets/images/webp/home/main3.webp";
import main4Webp from "../../assets/images/webp/home/main4.webp";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import { useTranslation } from "react-i18next";
import React from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const VisualWrap = styled.section`
    .title-area {margin-bottom:60px;}
    .title {font-size:36px; font-weight:600;}
    .subtitle {font-size:26px; font-weight:400;}
    position:relative; 
    height:100vh; 
    overflow:hidden;
    picture { width: 100%; }
    .visual-slide {
        height:100%;
        img, source {
            width:100%; 
            height:100%; 
            object-fit:cover;
        }
    }
    .visual-contents {
        position:absolute; 
        z-index:1; 
        width:100%; 
        height:100%; 
        display:flex; 
        align-items:center;
        justify-content:center; 
        top:0; left:0;
    }
    .swiper-pagination {
        left:50%; 
        bottom:20px; 
        right:auto; 
        width:auto; 
        background-color:#353535; 
        padding:14px 20px; 
        border-radius:50px; 
        transform:translateX(-50%); 
        display:flex; 
        gap:7px;
    }
    .swiper-pagination-bullet {
        background-color:#fff; 
        opacity:1; 
        transition:width .5s ease; 
        border-radius:5px; 
        height:5px; 
        width:5px; 
        margin:0 !important;
    }
    .swiper-pagination-bullet-active { width:44px; }
    @media screen and (max-width: 767px) {
        .point-item { font-size: ${props => props.$language === "jp" ? "12px !important" : "14px"} }
    }
`;
const VisualContents = styled.div`
    @media screen and (max-width: 767px) { 
        .title { font-weight: 600 !important; } 
        .mb-20 { margin-bottom: 20px !important; }
    }
    .title {
        text-shadow:1px 1px 5.1px rgba(0, 0, 0, 0.25);
        font-size:86px;
        // font-weight:600; 
        display:block; 
        margin-bottom:20px;
        color:#fff;
    }
    .btn-contact {
        background-color:#353535; 
        color:#fff; 
        font-size:20px; 
        line-height:1; 
        padding:12px 30px; 
        border-radius:100px; 
        display:inline-flex;
        align-items:center;
        transition: transform 0.2s ease;
        svg { font-weight:200; }
        &:hover {
            transform: scale(0.97);
        }
    }
    .subtitle {
        color:#FFF; 
        margin-bottom:10px; 
        text-shadow:1px 1px 15.1px rgba(0, 0, 0, 0.25); 
        font-size:26px;
    }
    .point {
        display:flex; 
        gap:10px; 
        margin-bottom:40px;
    }
    .point-item {
        border-radius:15px; 
        color:#fff; 
        line-height:1.4; 
        border:1px solid #FFF; 
        background:rgba(255, 255, 255, 0.15); 
        backdrop-filter:blur(8px); 
        padding:20px;
    }
    .m-0 {
        margin: 0;
    }
`;
const PointItem = styled.div`
    border-radius:15px; 
    color:#fff; 
    line-height:1.4; 
    border:1px solid #FFF; 
    background:rgba(255, 255, 255, 0.15); 
    backdrop-filter:blur(8px); 
    padding:20px;
    @media screen and (max-width: 450px) {
        font-size: ${props => props.$language === "jp" ? "12px !important" : "14px"};
    }
    @media screen and (max-width: 400px) {
        font-size: ${props => props.$language === "jp" ? "11px !important" : "14px"};
    }
    @media screen and (max-width: 361px) {
        font-size: ${props => props.$language === "jp" ? "9px !important" : "14px"};
    }
`;

export default function Visual() {
    const { t, i18n } = useTranslation();

    const slideList = [
        { imgWebp: main1Webp, imgFallback: main1 },
        { imgWebp: main2Webp, imgFallback: main2 },
        { imgWebp: main3Webp, imgFallback: main3 },
        { imgWebp: main4Webp, imgFallback: main4 },
    ];

    const pointList = [
        { description1: t("mainPage.global"), description2: t("mainPage.photoBrand") },
        { description1: t("mainPage.spaceDesign"), description2: t("mainPage.conceptBooth") },
        { description1: t("mainPage.realtime"), description2: t("mainPage.updateSupport") },
    ];

    return (
        <VisualWrap className="visual">
            <Swiper
                className="visual-slide"
                speed={1000}
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                modules={[Pagination, Autoplay]}
            >
                {slideList.map((slide, idx) => (
                    <SwiperSlide key={idx} className="visual-slide">
                        <div className="swiper-wrapper">
                            <picture>
                                <source srcSet={slide.imgWebp} type="image/webp" />
                                <img
                                    src={slide.imgFallback}
                                    alt={`Photomag main visual ${idx + 1}`}
                                    loading={idx === 0 ? "eager" : "lazy"}
                                    width={1531}
                                    height={791}
                                />
                            </picture>
                            <VisualContents className="visual-contents">
                                <div className="inner">
                                    <p className="title" aria-hidden="true">PHOTOMAG</p>
                                    <p className="subtitle m-0" aria-hidden="true">{t("mainPage.title1")}</p>
                                    <p className="subtitle mb-20" aria-hidden="true">{t("mainPage.title2")}</p>
                                    <div className="point">
                                        {pointList.map((point, index) => (
                                            <PointItem key={index} className="point-item" $language={i18n.language}>
                                                <p aria-hidden="true">{point.description1}</p>
                                                <p aria-hidden="true">{point.description2}</p>
                                            </PointItem>
                                        ))}
                                    </div>
                                    <Link to="/inquiry/partner" className="btn-contact">
                                        {t("mainPage.inquriy")}<ChevronRightIcon style={{ fontSize: "1.3em" }} />
                                    </Link>
                                </div>
                            </VisualContents>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </VisualWrap>
    )
}