import styled from "styled-components";
import subHeaderBg from "../../../assets/images/store/sub_header_bg.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { callApi } from "../../../util/HttpService";
import ClassicEditor from "../../../ckeditor5/src/App.jsx";
import GoogleMaps from "../../../cmmn/GoogleMaps.jsx";
import { useTranslation } from "react-i18next";
import MetaTag from "../../../layout/MetaTag.js";

const Container = styled.div`
    @media screen and (max-width: 767px) {
        .title-tab .search {position: relative;margin-top:30px;}
        .store-list {grid-template-columns: repeat(1, 1fr);}
        .store-item .store-thumb {height:200px;}

        .page-title2 {font-size:24px !important;margin-bottom:30px !important;}
        .store-name {font-size:18px !important;}
        .store-address {display:block;font-size:15px !important;margin-top:6px;line-height:1.4;opacity:.7;}
        .root_daum_roughmap .wrap_map {height:250px !important;}
    }
    .page-title2 {font-size:36px;font-weight:600;margin-bottom:40px;}
    .btn.btn-black {
        display:inline-block; border-radius:4px; background:#343434; 
        color:#fff; text-align:center; padding:12px 40px; line-height:1;
    }
    .editor-container { width: 100% !important; }
    .ck-editor__editable_inline,
    .ck-editor__editable {
        border: none !important;
        box-shadow: none !important;
        pointer-events: none !important;
    }
    .editor-container__editor {
        width: 100% !important;
        max-width: 100% !important;
        min-width: unset;
    }
    .ck-sticky-panel__content {
        border: 1px solid #fff !important;
    }
    @media screen and (max-width: 767px) {
        .ck-editor__editable_inline,
        .ck-editor__editable {
            .image { width: 100% !important; }
        }
    }
`;
const StoreInfo = styled.div`
    border-bottom:1px solid #C6C6C6;padding-bottom:10px;margin-bottom:40px;
    .store-name {font-size:24px;font-weight:600;}
    .store-address {font-size:18px;}
`;
const StoreGallery = styled.div`
    padding-bottom:40px;border-bottom:1px solid #C6C6C6;
    margin-bottom:20px;margin-top:20px;
    img {
        margin-bottom:20px;
        &:last-child { margin-bottom:0; }
    }
`;

export default function StoreView() {

    const { pstsSqno } = useParams();
    const navigate = useNavigate();
    const [streInfo, setStreInfo] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        if (pstsSqno) {
            callApi("GET", null, `/web/api/public/psts-detail?pstsSqno=${pstsSqno}`)
                .then(res => {
                    // console.log(res);
                    setStreInfo(res);
                })
                .catch(err => {
                    console.error(err);
                })
        }
    }, [pstsSqno]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleEditorDataChange = (data) => {
        setStreInfo((prevData) => ({
            ...prevData,
            pstsCn: data,
        }));
    };

    return (
        <>
            <MetaTag
                title="Photomag - Store View"
                description="Photomag店にお越しいただき、感覚的なマガジンスタイルの写真をご体験いただけます。 特別な瞬間を撮ることができる様々なフォトアイテムとインテリアで、写真の中の瞬間をより一層輝かせてくれます。"
            />
            <Container className="container">
                <section>
                    <div className="page-header">
                        <div className="page-header-bg">
                            <img src={subHeaderBg} alt="Photomag store logo" />
                        </div>
                        <div className="inner">
                            <h2 className="page-title">{t("common.store")}</h2>
                            <p className="page-text"></p>
                        </div>
                    </div>
                    <div className="inner">
                        <h3 className="page-title2">{t("common.store")}</h3>
                        <StoreInfo className="store-info">
                            <strong className="store-name">{streInfo.pstsTitl}</strong>
                            <span className="store-address"> ({t("storePage.addr")} : {streInfo.streAddr})</span>
                        </StoreInfo>
                        <StoreGallery className="store-gallery">
                            <GoogleMaps data={streInfo} />
                            <ClassicEditor
                                name="pstsCn"
                                value={streInfo?.pstsCn || ""}
                                onChangeValue={handleEditorDataChange}
                                disabled={true}
                            />
                        </StoreGallery>
                        <div className="ta-center" style={{ cursor: "pointer" }}>
                            <div className="btn btn-black" onClick={() => navigate("/store")}>{t("common.list")}</div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}