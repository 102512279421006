import styled from "styled-components";
import subHeaderBg from "../../assets/images/gallery/sub_header_bg.png";
import galleryItemBg from "../../assets/images/gallery/gallery_item_bg.png";
import galleryEvent1 from "../../assets/images/gallery/gallery_event_1.png";
import galleryEvent2 from "../../assets/images/gallery/gallery_event_2.png";
import galleryEvent3 from "../../assets/images/gallery/gallery_event_3.png";
import galleryEvent4 from "../../assets/images/gallery/gallery_event_4.png";
import galleryEvent5 from "../../assets/images/gallery/gallery_event_5.png";
import galleryEvent6 from "../../assets/images/gallery/gallery_event_6.png";
import galleryEvent7 from "../../assets/images/gallery/gallery_event_7.png";
import galleryEvent8 from "../../assets/images/gallery/gallery_event_8.png";
import galleryEvent9 from "../../assets/images/gallery/gallery_event_9.png";

import gallerySticker1 from "../../assets/images/gallery/gallery_sticker_1.png";
import gallerySticker2 from "../../assets/images/gallery/gallery_sticker_2.png";
import gallerySticker3 from "../../assets/images/gallery/gallery_sticker_3.png";
import gallerySticker4 from "../../assets/images/gallery/gallery_sticker_4.png";
import gallerySticker5 from "../../assets/images/gallery/gallery_sticker_5.png";
import gallerySticker6 from "../../assets/images/gallery/gallery_sticker_6.png";
import gallerySticker7 from "../../assets/images/gallery/gallery_sticker_7.png";
import gallerySticker8 from "../../assets/images/gallery/gallery_sticker_8.png";
import gallerySticker9 from "../../assets/images/gallery/gallery_sticker_9.png";
import gallerySticker10 from "../../assets/images/gallery/gallery_sticker_10.png";
import { useTranslation } from "react-i18next";
// import CustomBreadcrumbs from "../../layout/CustomBreadcrumbs";
import MetaTag from "../../layout/MetaTag";

const Container = styled.div`
    @media screen and (max-width: 767px) {
        .page-title2 {font-size:24px !important;margin-bottom:30px !important;}
        .page-text {word-break: unset !important;}
    }
    @media screen and (max-width: 1024px) {
        .gallery-list {grid-template-columns: repeat(3, 1fr);}
    }
    @media screen and (max-width: 1024px) {
        .gallery-list {grid-template-columns: repeat(3, 1fr);}
    }
    @media screen and (max-width: 767px) {
        .gallery-list {grid-template-columns: repeat(2, 1fr);gap:14px;margin:0 0 80px;}
        .gallery-list .gallery-item img {max-width:80%;max-height:80%;}
    }
`;
const GalleryList = styled.div`
    margin-top:40px;margin-bottom:80px;display:grid;
    grid-template-columns: repeat(4, 1fr);gap:20px;
    .gallery-item {
        border-radius: 18px;padding-bottom:100%;
        border: 1px solid rgba(255, 255, 255, 0.20);position:relative;
        background:url(${galleryItemBg}) lightgray 50% / cover;
        display:flex;align-items:center;justify-content:center;
        img {
            position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);
            max-width:90%;max-height:90%;
        }
    }
`;

export default function Gallery() {
    const { t } = useTranslation();
    return (
        <>
            <MetaTag
                title="ギャラリー - Photomag"
                description="PhotomagでMZ世代が好む様々なフレームをご覧いただけます。"
            />
            <Container className="container">
                <section>
                    <div className="page-header">
                        <div className="page-header-bg">
                            <img src={subHeaderBg} alt="Photomag Gallery logo" />
                        </div>
                        <div className="inner">
                            <h2 className="page-title">{t("common.gallery")}</h2>
                            <p className="page-text">{t("galleryPage.title")}</p>
                        </div>
                    </div>
                    {/* <CustomBreadcrumbs /> */}
                    <div className="inner">
                        <h3 className="page-title2">{t("galleryPage.eventFrame")}</h3>
                        <GalleryList className="gallery-list">
                            <div className="gallery-item"><img src={galleryEvent1} alt="Photomag gallery_event_1" /></div>
                            <div className="gallery-item"><img src={galleryEvent2} alt="Photomag gallery_event_2" /></div>
                            <div className="gallery-item"><img src={galleryEvent3} alt="Photomag gallery_event_3" /></div>
                            <div className="gallery-item"><img src={galleryEvent4} alt="Photomag gallery_event_4" /></div>
                            <div className="gallery-item"><img src={galleryEvent5} alt="Photomag gallery_event_5" /></div>
                            <div className="gallery-item"><img src={galleryEvent6} alt="Photomag gallery_event_6" /></div>
                            <div className="gallery-item"><img src={galleryEvent7} alt="Photomag gallery_event_7" /></div>
                            <div className="gallery-item"><img src={galleryEvent8} alt="Photomag gallery_event_7" /></div>
                            <div className="gallery-item"><img src={galleryEvent9} alt="Photomag gallery_event_7" /></div>
                        </GalleryList>
                        <h3 className="page-title2">{t("galleryPage.stickerFrame")}</h3>
                        <GalleryList className="gallery-list">
                            <div className="gallery-item"><img src={gallerySticker1} alt="Photomag gallery_sticker_1" /></div>
                            <div className="gallery-item"><img src={gallerySticker2} alt="Photomag gallery_sticker_2" /></div>
                            <div className="gallery-item"><img src={gallerySticker3} alt="Photomag gallery_sticker_3" /></div>
                            <div className="gallery-item"><img src={gallerySticker4} alt="Photomag gallery_sticker_4" /></div>
                            <div className="gallery-item"><img src={gallerySticker5} alt="Photomag gallery_sticker_5" /></div>
                            <div className="gallery-item"><img src={gallerySticker6} alt="Photomag gallery_sticker_6" /></div>
                            <div className="gallery-item"><img src={gallerySticker7} alt="Photomag gallery_sticker_7" /></div>
                            <div className="gallery-item"><img src={gallerySticker8} alt="Photomag gallery_sticker_8" /></div>
                            <div className="gallery-item"><img src={gallerySticker9} alt="Photomag gallery_sticker_9" /></div>
                            <div className="gallery-item"><img src={gallerySticker10} alt="Photomag gallery_sticker_10" /></div>
                        </GalleryList>
                    </div>
                </section>
            </Container>
        </>
    )
}