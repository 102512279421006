import styled from "styled-components";
import btnLang from "../assets/images/btn_lang.svg";
import langListArrow from "../assets/images/lang_list_arrow.svg";
import logo from "../assets/images/logo.svg";
import korean from "../assets/images/lang_korean.png";
import japanese from "../assets/images/lang_japanese.png";
import koreanWebp from "../assets/images/webp/header/lang_korean.webp";
import japaneseWebp from "../assets/images/webp/header/lang_japanese.webp";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Menu from "@mui/icons-material/Menu";

const HeaderWrap = styled.div`
    z-index:100;
    position:fixed;
    left:0;
    right:0;
    border-bottom: 1px solid #FFF;
    background: rgba(21, 21, 21, 0.20);
    backdrop-filter:blur(9px);
    header {
        display:flex;
        justify-content:space-between;
        align-items:center;
        height:62px;
        position:relative;
    }
    .hd-gnb {
        flex:auto;
        padding-left:50px;
        display:flex;
        gap:5%;
        a {
            color:#fff;
            font-weight:500;
            display:inline-block;
            padding:9px 12px;
            line-height:1;
            border-radius:6px;
            transition:background .3s ease;
            &:hover {
                background-color:rgba(255, 255, 255, 0.2);
            }
            &.active {
                background-color:rgba(255, 255, 255, 0.2);
            }
        }
    }
    .hd-contact {
        display:flex;
        gap:10px;
        a {
            display:inline-block;
            padding:9px 12px;
            line-height:1;
            font-weight:500;
            color:#fff;
            font-size:1.4rem;
            border-radius:6px;
            transition:background .3s ease;
            &:hover {
                background-color:rgba(255, 255, 255, 0.2);
            }
        }
    }
    .hd-etc {
        display:flex;
        align-items:center;
    }
    .btn-lang {
        margin-left:10px;
        display:inline-block;
        font-size:0;
        width:36px;
        height:36px;
        background: url(${btnLang}) no-repeat center;
        background-size:70% auto;border-radius:6px;
        transition:background .3s ease;
        &:hover {
            background-color:rgba(255, 255, 255, 0.2);
        }
    }
    .btn-menu {
        margin-left:10px;
        display:none;
        width:36px;
        height:36px;
        border-radius:6px;
        transition:background .3s ease;color:#fff;
        svg {
            font-size:30px;
            margin: auto;
            display:block;
        }
        &:hover {
            background-color:rgba(255, 255, 255, 0.2);
        }
    }
`;
const LangList = styled.ul`
    &:before {
        content:'';
        position:absolute;
        right:14px;
        bottom:90%;
        display:none;
        width:17px;
        height:17px;
        background:url(${langListArrow}) no-repeat center/contain;
    }
    opacity: 0;
    position:absolute;
    right:-4px;
    margin-top:10px;
    width:130px;
    padding:6px;
    border-radius:6px;
    box-shadow:0 2px 10px rgba(0, 0, 0, .1);
    background-color:#fff;
    display: none;
    z-index:1;
    &.active {
        animation: lang-ani 0.3s ease-in-out forwards;
        display: block;
        &:before {
            display: block;
        }
    }
    .lang-item {
        border-radius:6px;
        padding:6px 8px;
        display:flex;
        gap:10px;
        align-items:center;
        cursor:pointer;
        transition:background .3s ease;
        &:not(:first-child) {
            margin-top:3px;
        }
        &.active {
            background-color:#f8f8f8;
        }
        &:hover {
            background-color:#f8f8f8;
        }
    }
    .lang-flag {
        width:20px;
        height:20px;
    }
    .lang-name {
        font-size:14px;
        font-weight:500;
        color:#353535;
        flex:1;
        line-height:1;
    }
    @keyframes lang-ani {
        from {
            opacity: 0;
            transform: translateY(10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

function Header({ toggleMenu, setIsMenu }) {

    const { t, i18n } = useTranslation();

    const links = [
        { href: "/brand", key: t("common.brand"), label: "brand" },
        { href: "/product", key: t("common.product"), label: "product" },
        { href: "/gallery", key: t("common.gallery"), label: "gallery" },
        { href: "/board", key: t("common.board"), label: "board" },
        { href: "/store", key: t("common.store"), label: "store" },
    ];
    const contacts = [
        { href: "/inquiry/partner", key: t("common.partner"), label: "partner" },
        { href: "/inquiry/startup", key: t("common.startup"), label: "startup" },
        { href: "/inquiry/abroad", key: t("common.abroad"), label: "abroad" },
    ];

    const [isLangListVisible, setIsLangListVisible] = useState(false);
    const location = useLocation();

    const toggleLangList = () => {
        setIsLangListVisible(!isLangListVisible);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setIsLangListVisible(false);
    };

    const handleLinkClick = () => {
        setIsMenu(false);
        window.scrollTo(0, 0);
    };

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location.pathname])

    const isPathSelected = (path) => {
        return location.pathname === path || location.pathname.startsWith(path);
    };

    // 언어 목록에서 외부 클릭 시 메뉴 닫히게
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         const langList = document.querySelector('.lang-list');
    //         if (langList && !langList.contains(event.target)) {
    //             setIsLangListVisible(false);
    //         }
    //     };
    //     if (isLangListVisible) {
    //         document.addEventListener('click', handleClickOutside);
    //     }
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, [isLangListVisible]);

    return (
        <HeaderWrap className="header-wrap">
            <div className="inner">
                <header>
                    <Link to="/" onClick={handleLinkClick}>
                        <img className="hd-logo" src={logo} alt="Photomag logo" width={118} height={25} />
                    </Link>
                    <nav className="hd-gnb">
                        {links.map((link, idx) => (
                            <Link
                                to={link.href}
                                key={idx}
                                className={isPathSelected(link.href) ? 'active' : ''}
                                onClick={() => window.scrollTo(0, 0)}>
                                {link.key}
                            </Link>
                        ))}
                    </nav>
                    <div className="hd-contact">
                        {contacts.map((contact, idx) => (
                            <Link
                                to={contact.href}
                                key={idx}
                                className={isPathSelected(contact.href) ? 'active' : ''}>
                                {contact.key}
                            </Link>
                        ))}
                    </div>
                    <div className="hd-etc">
                        <div className="lang">
                            <button className="btn-lang" onClick={toggleLangList}>언어 변경</button>
                            <LangList className={`${isLangListVisible ? 'active' : ''} lang-list`}>
                                <li className={`lang-item ${i18n.language === 'jp' ? 'active' : ''}`} onClick={() => changeLanguage('jp')}>
                                    <span className="lang-flag">
                                        <picture>
                                            <source srcSet={japaneseWebp} type="image/webp" />
                                            <img src={japanese} alt="japanese" />
                                        </picture>
                                    </span>
                                    <strong className="lang-name">日本語</strong>
                                </li>
                                <li className={`lang-item ${i18n.language === 'ko' ? 'active' : ''}`} onClick={() => changeLanguage('ko')}>
                                    <span className="lang-flag">
                                        <picture>
                                            <source srcSet={koreanWebp} type="image/webp" />
                                            <img src={korean} alt="korean" />
                                        </picture>
                                    </span>
                                    <strong className="lang-name">한국어</strong>
                                </li>
                            </LangList>
                        </div>
                        <button className="btn-menu" onClick={toggleMenu}>
                            <Menu />
                        </button>
                    </div>
                </header>
            </div>
        </HeaderWrap>
    )
}
export default Header;