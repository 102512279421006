import { useEffect, useState } from "react";
import styled from "styled-components";

const RadioWrap = styled.div`
    display:flex; 
    gap:10px; 
    margin-bottom:40px; 
    flex-wrap:wrap;
    input[type="radio"] {
        display: none;
    }
    .radio {
        input {
            position:absolute; 
            left:0; top:0; 
            opacity:0; 
            width:0; 
            height:0; 
            cursor:pointer;
        }
        label {
            display: inline-block;
            padding:14px 20px; 
            line-height:1; 
            border:1px solid #5B5B5B; 
            border-radius:8px;
            cursor: pointer; 
            transition: transform 0.2s ease, background-color 0.2s ease, color 0.2s ease;
            &:hover {
                background-color:#343434; 
                color:#fff;
            }
        }
        input:checked + label {
            background-color:#343434; 
            color:#fff; 
            font-weight:600;
        }
    }
`;


export default function CmmnRadio(
    {
        className,
        dataArray,
        onSelectChange,
        disabled,
        defaultVal,
        tagName,
    }
) {

    const [radioValue, setRadioValue] = useState(defaultVal);

    useEffect(() => {
        setRadioValue(defaultVal);
    }, [defaultVal]);

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setRadioValue(value);
        if (onSelectChange) {
            onSelectChange(e);
        }
    };

    return (
        <RadioWrap className={`radio-area ${className || ''}`}>
            {dataArray.map((data, idx) => (
                <div className="radio" key={idx}>
                    <input 
                        type="radio"
                        name={tagName}
                        id={`${tagName}-${idx}`}
                        checked={radioValue === data.value}
                        onChange={handleRadioChange}
                        value={data.value}
                        disabled={disabled}
                    />
                    <label htmlFor={`${tagName}-${idx}`}>{data.label}</label>
                </div>
            ))}
        </RadioWrap>
    )
}