// 전화번호 포맷팅
export const getFormattedValue = (value) => {
    if (!value) return "";

    const cleanValue = value.replace(/[^0-9]/g, "");

    if (cleanValue.length === 11) {
        return cleanValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    } else if (cleanValue.length === 10) {
        return cleanValue.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else if (cleanValue.length === 9) {
        return cleanValue.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
    } else {
        return value;
    }
};

export const formatContentWithParagraphs = (text) => {
    return text
        .split(/\n/)  // 한 줄바꿈(\n)을 기준으로 분리
        .map(paragraph => `<p>${paragraph.trim() || "&nbsp;"}</p>`)  // 빈 줄은 <p>&nbsp;</p>로 변환
        .join("");  // 모든 <p> 태그를 하나의 문자열로 합침
};