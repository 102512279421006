import { useState, useEffect } from 'react';
import 'ckeditor5/ckeditor5.css';

import './App.css';
import styled from 'styled-components';
import DOMPurify from 'isomorphic-dompurify';
import { notify, ToastProvider } from '../../cmmn/ToastAlert';
import { useTranslation } from "react-i18next";

const EditorWrap = styled.div`
	width: 100%;
	box-sizing: border-box;
	z-index: 1;
	margin-top: 2rem;
	.ck-reset_all {
		position: relative;
		z-index: 4 !important;
	}
  	.editor-container__editor {
		width: 100%;
		max-width: 1152px;
		min-width: 695px;
  	}
  	.ck-editor__editable {
		min-height: 600px;
		font-size: 14px;
  	}
  	@media (max-width: 1600px) {
		.ck-editor__editable {
			min-height: 400px;
		}
  	}
	.ck-editor__editable em, .ck-editor__editable i {
 	   font-style: italic !important;
	}
	strong u {
    	font-family: 'Pretendard-Bold';
	}
	.ck-content p {
    	font-size: 14px;
	}
	strong {
		font-weight: 600;
	}
`;
const debounce = (func, delay) => {
	let timeout;
	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), delay);
	};
};

export default function App({ value }) {
	const { t, i18n } = useTranslation();
	const [editorWidth, setEditorWidth] = useState('100%');
	const sanitizedHTML = DOMPurify.sanitize(value, {
		ADD_TAGS: ['iframe'],
		ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'src', 'style', 'width', 'height'],
	});

	useEffect(() => {
		const handleResize = debounce(() => {
			const windowWidth = window.innerWidth;
			// console.log(windowWidth);

			if (windowWidth >= 1700) {
				setEditorWidth('1152px');
			} else if (windowWidth > 1500) {
				setEditorWidth('838.1px');
			} else {
				setEditorWidth('695px');
			}
		}, 100);

		// 초기 설정 및 이벤트 리스너 등록
		handleResize();
		window.addEventListener('resize', handleResize);

		// Cleanup 함수: 이벤트 리스너 해제
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// 해시태그 클립보드 복사
	useEffect(() => {
		const hashtags = document.querySelectorAll('.hashtag');
		hashtags.forEach((span) => {
			span.addEventListener('click', () => {
				const text = span.innerText.replace(/^#\s*/, '');
				if (!navigator.clipboard) {
					alert(t("boardPage.clipboardCopyError"));
					return;
				}
				navigator.clipboard
					.writeText(text)
					.then(() => {
						notify(t("boardPage.clipboardCopySuccess"), 'success', i18n.language);
					})
					.catch((err) => {
						notify(t("boardPage.clipboardCopyFail"), 'error', i18n.language);
						console.error('복사 실패 : ', err);
					});
			});
		});

		return () => {
			hashtags.forEach((span) => {
				span.removeEventListener('click', () => { });
			})
		}
	}, [sanitizedHTML]);

	return (
		<EditorWrap>
			<div className="editor-container editor-container_classic-editor editor-container_include-style">
				{/* ck-content 클래스를 사용하여 CKEditor의 스타일을 유지 */}
				<div className="editor-container__editor ck-content">
					{/* HTML 데이터를 직접 렌더링 */}
					<div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
					<ToastProvider />
				</div>
			</div>
		</EditorWrap>
	);
}
