import styled from "styled-components";
import startupStep1 from "../../../assets/images/contact/startup_step_1.png";
import startupStep2 from "../../../assets/images/contact/startup_step_2.png";
import startupStep3 from "../../../assets/images/contact/startup_step_3.png";
import startupStep4 from "../../../assets/images/contact/startup_step_4.png";
import startupStep5 from "../../../assets/images/contact/startup_step_5.png";
import startupStep6 from "../../../assets/images/contact/startup_step_6.png";
import startupStep7 from "../../../assets/images/contact/startup_step_7.png";
import startupStep8 from "../../../assets/images/contact/startup_step_8.png";
import { useTranslation } from "react-i18next";

const ContactStep = styled.div`
    .cs-jp {
        font-size: ${props => props.$jp ? "18px !important" : "20px !important"};
    }
    @media screen and (max-width: 767px) {
        .cs-jp { 
            font-size: ${props => props.$jp ? "15px !important" : "16px !important"}; 
        }
    }
    grid-template-columns:repeat(4, 1fr); display:grid; max-width:760px; 
    margin:0 auto 90px; gap:40px 0;
    .step-box { 
        text-align:center; 
        & > strong {
            display:block; font-size:20px; font-weight:600; margin-bottom:10px;
        }
        & > span {
            line-height:1.3;
        }
        .step-icon {
            position:relative; width:132px; height:132px; border-radius:50%; 
            background-color:#F9FAFB; display:flex; align-items:center; 
            justify-content:center; margin:0 auto 20px;
            &:after {
                content:''; position:absolute; display:block; top:50%; 
                transform:translateY(-50%); right:-100%; width:100%; height:1px; 
                background-color:#EDEDED;
            }
        }
        &:last-child .step-icon:after {
            content:none;
        }
        &:nth-child(4n) .step-icon:after {
            content:none;
        }
    }
`;
export default function Startup() {
    const { t, i18n } = useTranslation();
    const jp = i18n.language === "jp";
    
    return (
        <ContactStep className="contact-step" $jp={jp}>
            <div className="step-box">
                <div className="step-icon"><img src={startupStep1} alt="" /></div>
                <strong>{t("startupPage.stpCst")}</strong>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={startupStep2} alt="" /></div>
                <strong>{t("startupPage.agreement")}</strong>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={startupStep3} alt="" /></div>
                <strong>{t("startupPage.interior1")}<br />{t("startupPage.interior2")}</strong>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={startupStep4} alt="" /></div>
                <strong className="cs-jp">{t("startupPage.installation1")}<br />{t("startupPage.installation2")}</strong>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={startupStep5} alt="" /></div>
                <strong>{t("startupPage.training")}</strong>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={startupStep6} alt="" /></div>
                <strong>{t("startupPage.final")}</strong>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={startupStep7} alt="" /></div>
                <strong>{t("startupPage.open")}</strong>
            </div>
            <div className="step-box">
                <div className="step-icon"><img src={startupStep8} alt="" /></div>
                <strong>{t("startupPage.supprot")}</strong>
            </div>
        </ContactStep>
    )
}