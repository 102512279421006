import i18n from "i18next"
import { initReactI18next } from "react-i18next";
import translationKO from "./ko/translation.json";
import translationJP from "./jp/translation.json";

const resources = {
    ko: {
        translation: translationKO
    },
    jp: {
        translation: translationJP
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "jp",
    fallbackLng: "jp", //번역파일에서 찾을 수 없는 경우 기본 언어
    interpolation: {
        escapeValue: false
    }
});

export default i18n;