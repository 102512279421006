import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";

const uiOption = {
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    streetViewControl: false,
    styles: [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
        }
    ]
};
const containerStyle = {
    width: '100%',
    height: '400px'
};

const libraries = ["places"];

function GoogleMaps({ data, onChange }) {
    const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    // 지도 로드
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleApiKey,
        libraries: libraries,
    });

    const defaultCenter = {
        lat: data.streLocXcvl ? parseFloat(data.streLocXcvl) : 37.5665,
        lng: data.streLocYcvl ? parseFloat(data.streLocYcvl) : 126.9780,
    };

    if (loadError) {
        return <div>지도를 불러오는 중 오류가 발생했습니다.</div>;
    }

    if (!isLoaded) {
        return <div>지도를 불러오는 중입니다...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={defaultCenter}
            zoom={15}  // 적절한 줌 레벨 설정
            options={uiOption}
        >
            <MarkerF position={defaultCenter} />  {/* 마커 설정 */}
        </GoogleMap>
    );

}
export default GoogleMaps;