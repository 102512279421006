import Visual from "./main-sections/Visual";
import Focus from "./main-sections/Focus";
// import LineBanner from "./main-sections/LineBanner";
// import Special from "./main-sections/Special";
// import Store from "./main-sections/Store";
// import Partnership from "./main-sections/Partnership";
import { lazy, Suspense, useRef } from "react";
import MetaTag from "../layout/MetaTag";

// const Visual = lazy(() => import("./main-sections/Visual"));
// const LineBanner = lazy(() => import("./main-sections/LineBanner"));
// const Focus = lazy(() => import("./main-sections/Focus"));
const Special = lazy(() => import("./main-sections/Special"));
const Store = lazy(() => import("./main-sections/Store"));
const Partnership = lazy(() => import("./main-sections/Partnership"));

export default function Main() {

    const spcPrevRef = useRef(null);
    const spcNextRef = useRef(null);

    const strPrevRef = useRef(null);
    const strNextRef = useRef(null);

    return (
        <>
            <MetaTag
                title="Photomag"
                description="PHOTOMAGで雑誌のような写真で素晴らしい瞬間を捉えましょう。グローバルフォトブランド、空間デザイン、リアルタイム更新を対応します。"
                keywords="Photomag, グローバル, フォトマグ, セルフ写真館, フォトブース, 写真館, エレベーター·ブース, セルフ撮影"
            />
            <div className="container">

                <Visual />

                <Focus />
                {/* 이벤트가 있으면 주석 해제 */}
                {/* <LineBanner /> */}

                <Suspense fallback={<div>Loading Special Section...</div>}>
                    <Special prevRef={spcPrevRef} nextRef={spcNextRef} />
                </Suspense>

                <Suspense fallback={<div>Loading Store Section...</div>}>
                    <Store prevRef={strPrevRef} nextRef={strNextRef} />
                </Suspense>

                <Suspense fallback={<div>Loading Partnership Section...</div>}>
                    <Partnership />
                </Suspense>

            </div>
        </>
    )
}