import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const MenuWrap = styled.div`
    display:block;
    position:fixed;
    left:0;
    top:0;
    bottom:0;
    right:0;
    opacity:0;
    visibility: hidden;
    background-color:rgba(0, 0, 0, .6);
    backdrop-filter:blur(9px);
    padding-top:62px;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    &.active {
        z-index:99;
        visibility: visible;
        opacity:1;
    }
    .menu-navigation a {
        display:block;
        color:#fff;
        padding:20px 20px;
        border-bottom:1px solid rgba(255, 255, 255, 0.4);
        font-size:16px;
        font-weight:500;
    }
    .menu-contact {
        margin-top:20px;
        padding:0 20px;
        a {
            display:inline-block;
            color:#fff;
            &:after {
                content:'|';
                vertical-align:0;
                font-size:14px;
                margin:0 6px 0 10px;
                color:#aaa;
            }
            &:last-child:after {
                content:none;
            }
        }
    }
`;

function SideMenu({ isMenu, setIsMenu }) {

    const { t, i18n } = useTranslation();

    const links = [
        { href: "/brand", key: t("common.brand"), label: "brand" },
        { href: "/product", key: t("common.product"), label: "product" },
        { href: "/gallery", key: t("common.gallery"), label: "gallery" },
        { href: "/board", key: t("common.board"), label: "board" },
        { href: "/store", key: t("common.store"), label: "store" },
    ];
    const contacts = [
        { href: "/inquiry/partner", key: t("common.partner"), label: "partner" },
        { href: "/inquiry/startup", key: t("common.startup"), label: "startup" },
        { href: "/inquiry/abroad", key: t("common.abroad"), label: "abroad" },
    ];

    const handleLinkClick = () => {
        setIsMenu(false);
        window.scrollTo(0, 0);
    };

    return (
        <MenuWrap className={`${isMenu ? "active" : ""} menu-wrap`}>
            <nav className="menu-navigation">
                {links.map(link => (
                    <React.Fragment key={link.label}>
                        <Link to={link.href} onClick={handleLinkClick}>{link.key}</Link>
                    </React.Fragment>
                ))}
            </nav>
            <div className="menu-contact">
                {contacts.map(contact => (
                    <React.Fragment key={contact.label}>
                        <Link to={contact.href} onClick={handleLinkClick}>{contact.key}</Link>
                    </React.Fragment>
                ))}
            </div>
        </MenuWrap>
    )
}
export default SideMenu;