import styled from "styled-components";
import subHeaderBg from "../../assets/images/board/sub_header_bg.png";
// import noticeThumb from "../../assets/images/board/notice_thumb.png";
// import eventThumb from "../../assets/images/board/event_thumb.png";
import { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { callApi } from "../../util/HttpService";
import { useTranslation } from "react-i18next";
import MetaTag from "../../layout/MetaTag";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Container = styled.div`
    .tab-pane { display: none; }
    .tab-pane.active { display: block; }
    .fade { opacity: 0; transition: opacity 0.2s ease-in-out; }
    .fade.show { opacity: 1; }
    @media screen and (max-width: 767px) {
        .title-tab {font-size:28px !important;margin-bottom:20px !important;}
        .category {margin-bottom:30px !important;}
        .notice-thumb {width:34% !important;flex:none !important;height:120px !important;}
        .notice-title {margin-bottom:10px !important;}
        .notice-cts {padding-right:0 !important;}
        .notice-date,
        .notice-view {position: unset !important;margin-top:12px !important;font-size:14px !important;display:inline-block !important;margin-right:6px !important;}
    }
    svg {
        font-size: 1.3em;
        color: inherit;
    }
`;
const TitleTab = styled.div`
    font-size:36px; font-weight:600; margin-bottom:40px;position:relative;
    .title-tab-btn {color:#C6C6C6;}
    .title-tab-btn.active {color:#333D4B;}
`;
const BoardType = styled.div`
    .category {
        display:flex; gap:10px; flex-wrap:wrap; margin-bottom:40px;
        & > span {
            border-radius:8px; border:1px solid #5B5B5B; display:inline-block; 
            line-height:1; padding:10px 14px; transition:all .3s ease; 
            cursor:pointer;
        }
        & > span.active {
            background-color:#343434; border-color:#343434; color:#fff; 
            font-weight:600;
        }
    }
`;
const NoticeList = styled.ul`
    .notice-item {
        padding:20px 0; border-bottom:1px solid #C6C6C6;
        transition:background .3s ease;
        .notice-link {display:flex; gap:20px; align-items:center; color:#333D4B; cursor: pointer;}
        &:hover {background-color:#F9FAFB;}
    }
    .notice-thumb {
        display:block; width:232px; height:135px; border-radius:8px; 
        overflow:hidden; background-color:#eee;
        img {width:100%; height:100%; object-fit:cover;}
    }
    .notice-cts {position:relative; flex:auto; padding-right:100px;}
    .notice-title {font-size:20px; font-weight:700; display:block; margin-bottom:15px;}
    .notice-text {
        color:#C6C6C6; line-height:1.4; display:-webkit-box; 
        -webkit-box-orient:vertical; -webkit-line-clamp:2; overflow:hidden; 
        text-overflow:ellipsis;
    }
    .notice-date {position:absolute; right:0; top:0;}
    .notice-view {position:absolute; right:0; bottom:0;}
`;
const Pagination = styled.div`
    display:flex; align-items:center; justify-content:center; gap:18px; 
    margin-top:40px;
    .pg-num.active {color:#4894FF; font-weight:600;}
    .pg-prev, .pg-next {display:inline-flex; align-items:center; justify-content:center;}
    .pg-prev.disabled, .pg-next.disabled {opacity:.3;}
`;
const EventList = styled.ul`
    display:grid; grid-template-columns:repeat(auto-fill, minmax(340px, 1fr)); 
    gap:20px;
    .event-item {
        border-radius:18px; border:1px solid rgba(255, 255, 255, 0.20); 
        overflow:hidden; background-color:#F9FAFB;
        .event-link {color:#333D4B; cursor: pointer;}
    }
    .event-thumb {
        height:228px; overflow:hidden;
        img {width:100%; height:100%; object-fit:cover;}
    }
    .event-title {font-size:20px; line-height:1.4; font-weight:600; display:block; margin-bottom:20px;}
    .event-cts {padding:20px;}
    .event-cts:after {content:''; display:block; clear:both;}
    .event-date {float:left; color:#B9B9B9;}
    .event-view {float:right; color:#B9B9B9;}
`;

export default function Board() {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [boardType, setBoardType] = useState("notice");
    const [blbdType, setBlbdType] = useState("BRND");
    const [eventType, setEventType] = useState("INPRGS");

    const defaultSearchData = {
        blbdCd: "",
        page: 0,
        size: 5,
        evntStatCd: null,
    };
    const [searchData, setSearchData] = useState({
        ...defaultSearchData,
        blbdCd: "BRND",
    });
    const [pstsList, setPstsList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        if (searchData.blbdCd) {
            let params;
            if (searchData.blbdCd === "EVNT") {
                params = { ...searchData, blbdCd: searchData.blbdCd, evntStatCd: eventType };
            } else {
                params = { ...searchData };
            }
            // console.log(params);

            callApi("GET", params, "/web/api/public/psts-page")
                .then(res => {
                    // console.log(res);
                    setPstsList(res.content);
                    setTotalPages(res.totalPages);
                })
                .catch(err => {
                    console.error(err);
                })
        }
    }, [searchData, eventType]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBoardTypeChange = (boardType) => {
        if (boardType === "event") {
            setSearchData({ ...searchData, blbdCd: "EVNT" });
            setBlbdType("EVNT");
            setBoardType("event");
        } else {
            setBlbdType("BRND");
            setSearchData({ ...searchData, blbdCd: "BRND" });
            setBoardType("notice");
        }
    };

    const handleBlbdCdTypeChange = (blbdCd) => {
        setBlbdType(blbdCd);
        setSearchData({ ...defaultSearchData, blbdCd: blbdCd });
    };

    const handleLinkClick = (pstsSqno) => {
        navigate(`/board/board-view/${pstsSqno}`);
        const params = { ...searchData, pstsSqno: pstsSqno };
        callApi("POST", params, `/web/api/public/psts-view-count`)
            .then(res => {
                // console.log(res);
            })
            .catch(err => {
                console.error("ip추출 실패 : ", err);
                alert("접근 요청 실패: 네트워크를 확인해주세요.");
            })
    };

    const handlePageChange = (pageNum) => {
        setSearchData(prev => ({
            ...prev,
            page: pageNum - 1,
        }));
    };

    // useEffect(() => {
    //     console.log(searchData);
    // }, [searchData])
    
    return (
        <>
            <MetaTag
                title="お知らせ - Photomag"
                description="Photomagで進行する様々なイベントと特別な恩恵を会ってみてください。顧客様にもっと優れた経験を提供するため、最新ニュースとプロモーション情報を迅速に伝えて上げます。"
                // noIndex={true}
            />
            <Container className="container">
                <section>
                    <div className="page-header">
                        <div className="page-header-bg">
                            <img src={subHeaderBg} alt="Photomag board logo" />
                        </div>
                        <div className="inner">
                            <h2 className="page-title">{t("common.board")}</h2>
                            <p className="page-text"></p>
                        </div>
                    </div>
                    <div className="inner">
                        <TitleTab className="title-tab">
                            <button className={`title-tab-btn ${boardType === 'notice' ? 'active' : ''}`} onClick={() => handleBoardTypeChange("notice")}>{t("boardPage.notice")}</button>
                            {/* ㅣ */}
                            {/* <button className={`title-tab-btn ${boardType === 'event' ? 'active' : ''}`} onClick={() => handleBoardTypeChange("event")}>{t("boardPage.event")}</button> */}
                        </TitleTab>
                        <Tab.Container activeKey={boardType}>
                            <Tab.Content>
                                <Tab.Pane eventKey={"notice"}>
                                    <BoardType>
                                        <div className="category">
                                            <span className={`${blbdType === "BRND" ? 'active' : ''}`} onClick={() => handleBlbdCdTypeChange("BRND")}>{t("boardPage.brand")}</span>
                                            {/* <span className={`${blbdType === "NEWS" ? 'active' : ''}`} onClick={() => handleBlbdCdTypeChange("NEWS")}>{t("boardPage.news")}</span> */}
                                        </div>
                                        <Tab.Container activeKey={blbdType}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey={"BRND"}>
                                                    <NoticeList>
                                                        {pstsList.map((data, idx) => (
                                                            <li className="notice-item" key={data.pstsSqno}>
                                                                <div className="notice-link" onClick={() => handleLinkClick(data.pstsSqno)}>
                                                                    <div className="notice-thumb">
                                                                        <img src={`https://photomag-board-upload-file.s3.amazonaws.com/${data.imgPathAddr}/${data.imgFileNm}`} alt="Photomag brand logo" />
                                                                    </div>
                                                                    <div className="notice-cts">
                                                                        <strong className="notice-title">{data.pstsTitl}</strong>
                                                                        <p className="notice-text">{data.pstsBrifCn}</p>
                                                                        <span className="notice-date">{data.convRegDttm}</span>
                                                                        <span className="notice-view">{t("boardPage.views")} {data.iqryCnt}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </NoticeList>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey={"NEWS"}>
                                                    <NoticeList>
                                                        {pstsList.map((data, idx) => (
                                                            <li className="notice-item" key={data.pstsSqno}>
                                                                <div className="notice-link" onClick={() => handleLinkClick(data.pstsSqno)}>
                                                                    <div className="notice-thumb">
                                                                        <img src={`https://photomag-board-upload-file.s3.amazonaws.com/${data.imgPathAddr}/${data.imgFileNm}`} alt="Photomag news logo" />
                                                                    </div>
                                                                    <div className="notice-cts">
                                                                        <strong className="notice-title">{data.pstsTitl}</strong>
                                                                        <p className="notice-text">{data.pstsBrifCn}</p>
                                                                        <span className="notice-date">{data.convRegDttm}</span>
                                                                        <span className="notice-view">{t("boardPage.views")} {data.iqryCnt}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </NoticeList>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                        <Pagination className="pagination">
                                            <button
                                                className={`pg-prev ${searchData.page === 0 ? "disabled" : ""}`}
                                                onClick={() => handlePageChange(searchData.page)}
                                                disabled={searchData.page === 0}
                                            >
                                                <ChevronLeftIcon />
                                            </button>
                                            {[...Array(totalPages)].map((_, index) => (
                                                <button
                                                    key={index}
                                                    className={`pg-num ${searchData.page === index ? "active" : ""}`}
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </button>
                                            ))}
                                            <button
                                                className={`pg-next ${searchData.page === totalPages - 1 ? "disabled" : ""}`}
                                                onClick={() => handlePageChange(searchData.page + 2)}
                                                disabled={searchData.page === totalPages - 1}
                                            >
                                                <ChevronRightIcon />
                                            </button>
                                        </Pagination>
                                    </BoardType>
                                </Tab.Pane>
                                <Tab.Pane eventKey={"event"}>
                                    <BoardType>
                                        <div className="category">
                                            <span className={`${eventType === "INPRGS" ? 'active' : ''}`} onClick={() => setEventType("INPRGS")}>{t("boardPage.progress")}</span>
                                            <span className={`${eventType === "END" ? 'active' : ''}`} onClick={() => setEventType("END")}>{t("boardPage.end")}</span>
                                        </div>
                                        <Tab.Container activeKey={eventType}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey={"INPRGS"}>
                                                    <EventList>
                                                        {pstsList.map((data, idx) => (
                                                            <li className="event-item" key={data.pstsSqno}>
                                                                <div className="event-link" onClick={() => handleLinkClick(data.pstsSqno)}>
                                                                    <div className="event-thumb">
                                                                        <img src={`https://photomag-board-upload-file.s3.amazonaws.com/${data.imgPathAddr}/${data.imgFileNm}`} alt="Photomag event(INPRGS) logo" />
                                                                    </div>
                                                                    <div className="event-cts">
                                                                        <strong className="event-title">{data.pstsTitl}</strong>
                                                                        <span className="event-date">{data.convRegDttm}</span>
                                                                        <span className="event-view">{t("boardPage.views")} {data.iqryCnt}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </EventList>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey={"END"}>
                                                    <EventList>
                                                        {pstsList.map((data, idx) => (
                                                            <li className="event-item" key={data.pstsSqno}>
                                                                <div className="event-link" onClick={() => handleLinkClick(data.pstsSqno)}>
                                                                    <div className="event-thumb">
                                                                        <img src={`https://photomag-board-upload-file.s3.amazonaws.com/${data.imgPathAddr}/${data.imgFileNm}`} alt="Photomag event(END) logo" />
                                                                    </div>
                                                                    <div className="event-cts">
                                                                        <strong className="event-title">{data.pstsTitl}</strong>
                                                                        <span className="event-date">{data.convRegDttm}</span>
                                                                        <span className="event-view">{t("boardPage.views")} {data.iqryCnt}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </EventList>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                        <Pagination className="pagination">
                                            <button
                                                className={`pg-prev ${searchData.page === 0 ? "disabled" : ""}`}
                                                onClick={() => handlePageChange(searchData.page)}
                                                disabled={searchData.page === 0}
                                            >
                                                <ChevronLeftIcon />
                                            </button>
                                            {[...Array(totalPages)].map((_, index) => (
                                                <button
                                                    key={index}
                                                    className={`pg-num ${searchData.page === index ? "active" : ""}`}
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </button>
                                            ))}
                                            <button
                                                className={`pg-next ${searchData.page === totalPages - 1 ? "disabled" : ""}`}
                                                onClick={() => handlePageChange(searchData.page + 2)}
                                                disabled={searchData.page === totalPages - 1}
                                            >
                                                <ChevronRightIcon />
                                            </button>
                                        </Pagination>
                                    </BoardType>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section>
            </Container >
        </>
    )
}